<template lang="">
  <div >
    <Navbar />
    <section class="page-title page-title-1" id="page-title">
      <div class="page-title-wrap bg-overlay bg-overlay-dark-2">
        <div class="bg-section">
          <img
          :src="require('../../../../assets/images/page-titles/99.jpg')"
            alt="Background"
          />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="title">
                <h1 class="title-heading">
                  Şebekeye Bağlı Sistemler (On Grid)
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-wrap">
        <div class="container">
          <ol class="breadcrumb d-flex">
            <li class="breadcrumb-item">
              <router-link to="/tr/Anasayfa">Ana Sayfa</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <a href="#">Hizmetlerimiz</a>
            </li>
          </ol>
        </div>
      </div>
    </section>

    <section class="service-single" id="service-single">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4 order-1">
            <div class="sidebar sidebar-service">
              <div class="widget widget-services">
                <div class="widget-title">
                  <h5>Hizmetlerimiz</h5>
                </div>
                <div class="widget-content">
                  <ul class="list-unstyled">
                        <li><router-link to="/tr/Hizmetler/SebekedenBagimsizSistemler"> <span>Şebekeden Bağımsız Sistemler (Off-Grid)</span><i class="energia-arrow-right"></i></router-link></li>
                        <li><router-link to="/tr/Hizmetler/ElektrikliAracSarjIstasyonu"><span>Elektrikli Araç Sarj İstasyonu</span><i class="energia-arrow-right"></i></router-link></li>
                        <li><router-link to="/tr/Hizmetler/SebekeyeBagliSistemler"> <span>Şebekeye Bağlı Sistemler (On Grid)</span><i class="energia-arrow-right"></i></router-link></li>
                        <li><router-link to="/tr/Hizmetler/HibritGunesEnerjisiSistemleri"> <span>Hibrit Güneş Enerjisi Sistemleri (Grid On Tie)</span><i class="energia-arrow-right"></i></router-link></li>
                        <li><router-link to="/tr/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri"> <span>Güneş Enerjisi ile Tarımsal Sulama Sistemleri</span><i class="energia-arrow-right"></i></router-link></li>
                        <li><router-link to="/tr/Hizmetler/MobilGESUygulamalari"> <span>Mobil GES Uygulamaları </span><i class="energia-arrow-right"></i></router-link></li>
                    </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-8 order-0 order-lg-2">
            <div class="service-entry">
              <div class="entry-content">
                <div class="entry-introduction entry-infos">
                  <h5>Şebekeye Bağlı Sistemler (On Grid)</h5>
                  <div class="advantages-list-holder">
                    <p>
                      On Grid güneş enerjisi sistemleri büyük ölçekte arazi ve
                      çatı tipi olabileceği gibi bireysel konutların elektrik
                      ihtiyacını karşılayabilecek mikro GES şeklinde de
                      yapılabilmektedir. Bu tür solar sistemler, evsel kulanım
                      veya yüksek güçte santral sistemlerinde kullanılır. Güneş
                      panelleri tarafından üretilen elektrik, merkezi şehir
                      sistemine bağlı yüksek çevrim gücü olan inverterler ile
                      şebekeye iletilir. Amaç çatınızda üretilen elektrik ile
                      hem faturalarınızın maliyetini düşürmek hem de fazla
                      üretilen elektrik ile küçükte olsa bir kazanç sağlamaktır.
                      <p>
                        <br />
                    </p>
                      Tüm dünyada artan hava kirliliği ve enerji ihtiyacını
                      karşılamak için doğal kaynaklarından elektrik enerjisi
                      üretimi hızla artış göstermektedir. Her alanda artan
                      enerji ihtiyacının çözüm yolu yenilenebilir enerji
                      kaynaklarından geçmektedir. Devlet veya devlet destekli
                      kurumların, güneş enerji santralleri inşası, güneş
                      tarlaları kurulması ve enerji üretimi için yaptığı GES
                      teşvikleri bulunmaktadır.
                    </p>
                    <br />
                    <img :src="require('../../../../assets/gt/On-Grid-1024x256.jpg')" alt="OnGrid" />
                    <br />
                    <br />
                    <p><b> Şebeke Bağlantılı On Grid Güneş Enerjisi Sistemlerinin 4 Ana Malzemesi Vardır: </b></p>
                    <br />
                    <div class="advantages-list-holder">
                      <ul class="list-unstyled advantages-list">
                      <li>Güneş paneli</li>
                      <li>On Grid İnverter</li>
                      <li>Çift yönlü sayaç (Şebeke Sayacı) </li>
                      <li>GES panosu</li>
                    </ul>
                  </div>
                    <br /><br />

                    <h3>On Grid Sistemler</h3>
                    <br />
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <b>Şebeke Bağlantılı On Grid Güneş Enerjisi Sistemlerinin Avantajları:</b>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="advantages-list-holder">
                            <ul class="list-unstyled advantages-list">
                              <li>Sistemde depolama cihazı kullanılmadığı için ek maliyet oluşturmaz. Üretilen enerji şebekeyle bağlantılı olduğu için fazla enerji satılabilir.</li>
                              <li>Sistemde üretilen fazla enerji satılabildiği için gelir elde edilebilecektir.</li>
                              <li>Güneş panellerinin 25 yıl ömür beklentisi olduğundan birkaç yıl içinde maliyetini kâra çevirerek uzun vadeli bir yatırım sağlayacaktır. </li>
                              <li>İstenilen boyut ve alana göre sistem kurulumu yapılabileceği için esnek bir yapıya sahiptir.</li>
                              <li>Alan yeterli olduğu taktirde sistem kurulu gücü arttırılabilmektedir.</li>
                            </ul>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <b>Şebeke Bağlantılı On Grid Güneş Enerjisi Sistemlerinin Kullanım Alanları:</b>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body"> 
                            <div class="advantages-list-holder">
                            <ul class="list-unstyled advantages-list">
                              <li>Şehir elektriği kullanılan konutlar</li>
                              <li>Otel ve apartlar</li>
                              <li>Güneş enerjisi santralleri</li>
                              <li>Organize sanayi, şehir içindeki işletme ve fabrikalar</li>
                              <li>Çiftlikler</li>
                              <li>Soğuk hava depoları</li>
                              <li>Tarımsal sulamalar</li>
                              <li>Alışveriş merkezleri</li>
                            </ul>
                          </div>
                            <br />
                            <p>Bugün dünya genelinde yenilenebilir enerji kaynaklarından en çok artışı gösteren güneş enerjisidir. Düşük maliyeti, kolay uygulanabilir olması ve yatırılan paranın kısa sürede kazanç olarak geri gelmesi güneş enerjisini tercih sebebi yapmaktadır.</p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <b>Güneş Enerjisi Santralleri (Güneş Tarlaları)</b>
                          </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            Türkiye coğrafi konum bakımından güneş ışınlarını en çok alan ülkelerden biridir. Her ne kadar güneş enerjisinden yararlanma konusunda geç kalınmış olsa da, devlet teşviki ve çıkarılan kanunlarla birlikte büyük atılımlar gözleniyor. Her yıl birbirinden devasa GES’ler yurdun dört bir yanında devreye giriyor. Yüzlerce dönüm araziye güneş panelleri kurulduğu için doğal olarak halk dilinde “güneş tarlası” olarak adlandırılıyor. Güneş tarlaları sadece bireysel konutların veya şirketlerin enerji ihtiyacını karşılamak için kullanılmamaktadır. Ayrıca güneş tarlalarından elde edilen enerji kamuya ait elektrik şebekesine aktarılarak bireysel olarak güneş panellerine sahip olmayan konutların, kuruluşların ya da şirketlerin elektrik enerjisi ihtiyacı yenilenebilir kaynaklardan karşılanmış olur. Yenilenebilir Enerji Genel Müdürlüğü, YEGM’e göre ülkemizde her 1 metrekareye yılda ortalama 1.311 kWh güneş enerjisi düşmektedir. Ülkemizin yıllık toplam güneşlenme süresi ise 2.640 saattir. Güneş enerjisinden en iyi yararlanan, Ekvator’un 35° Kuzey ve 35° Güney enlemleri arasında yer alan bölge yıllık 2.000-3.500 saat güneş almaktadır. Bu veriler, Türkiye’nin güneş enerjisinden verimli bir şekilde yararlanmak için oldukça uygun bir coğrafi konuma sahip olduğunu göstermektedir.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              <b>Sistemin Temel Bileşenleri</b>
                          </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <div class="advantages-list-holder">
                            <ul class="list-unstyled advantages-list">
                                <li style="list-style-type: circle">Fotovoltaik Panel</li>
                                <li style="list-style-type: circle">Invertör</li>
                                <li style="list-style-type: circle">Çift Yönlü sayaç (Şebeke sayacı)</li>
                                <li style="list-style-type: circle">Tracker</li>
                              </ul>
                            </div>
                              <br />
                              <p>
                                <b>Tracker:</b> Ges takip sistemi, verimliliği bir tık öteye taşıyıp güneşten yararlanma süresini gün boyuna taşıyan sistemdir. Güneş takip sistemi sayesinde, güneş enerjisinden gün boyu yararlanabilme olanağı ile kaybolan kısmın tekrar sisteme katılımı ve verimin artması sağlanabiliyor. Güneşten gün boyu yararlanmak amacıyla sabit sistemlerin güneye bakması önem teşkil edip, enerji verimliliğini artırdığı bilinen bir gerçektir.<b> Uzaktan İzleme Sistemi</b> Solax / Tommatech Cloud uygulanması sayesinde dünyanın neresinde olursanız olun, Wi-Fi bağlantınız olduğu sürece sistemi uzaktan kontrol edebilirsiniz. Bu sistemde mevcut üretimi görüntüleyebilir, ne kadar tasarruf yaptığınızı hatta kaç ağaç kurtardığınızı inceleyebilirsiniz. Portal sayesinde pilinizin durumunu kontrol edip, canlı verileri görüntüleyebilirsiniz. Raporlama araçlarını kullanarak geçmiş performans verilerini de kontrol edebilirsiniz.
                              </p>
                            </div>
                        </div>
                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import vendor from "../../../../assets/js/vendor";
import Navbar from "../../SubPages-TR/navbar.vue";
import Footer from "../../SubPages-TR/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    (function ($) {
      "use strict";
      // $(window).on("load", function () {
      //     $(".preloader").fadeOut(5000);
      //     $(".preloader").remove();
      // });
      var $bgSection = $(".bg-section");
      var $bgPattern = $(".bg-pattern");
      var $colBg = $(".col-bg");
      $bgSection.each(function () {
        var bgSrc = $(this).children("img").attr("src");
        var bgUrl = "url(" + bgSrc + ")";
        $(this).parent().css("backgroundImage", bgUrl);
        $(this).parent().addClass("bg-section");
        $(this).remove();
      });
      $bgPattern.each(function () {
        var bgSrc = $(this).children("img").attr("src");
        var bgUrl = "url(" + bgSrc + ")";
        $(this).parent().css("backgroundImage", bgUrl);
        $(this).parent().addClass("bg-pattern");
        $(this).remove();
      });
      $colBg.each(function () {
        var bgSrc = $(this).children("img").attr("src");
        var bgUrl = "url(" + bgSrc + ")";
        $(this).parent().css("backgroundImage", bgUrl);
        $(this).parent().addClass("col-bg");
        $(this).remove();
      });
      var $moduleSearch = $(".module-icon-search"),
        $searchWarp = $(".module-search-warp");
      $moduleSearch.on("click", function () {
        $(this).parent().addClass("module-active");
        $(this).parent().siblings().removeClass("module-active");
        $searchWarp.addClass("search-warp-active");
      });
      var $moduleCart = $(".module-icon-cart"),
        $cartWarp = $(".module-cart-warp");
      $moduleCart.on("click", function () {
        $(this).parent().toggleClass("module-active");
        $(this).parent().siblings().removeClass("module-active");
      });
      var $module = $(".module"),
        $moduleWarp = $(".module-warp"),
        $moduleCancel = $(".module-cancel");
      $moduleCancel.on("click", function (e) {
        $module.removeClass("module-active");
        $searchWarp.removeClass("search-warp-active");
        e.stopPropagation();
        e.preventDefault();
      });
      $(document).keyup(function (e) {
        if (e.key === "Escape") {
          $module.removeClass("module-active");
          $moduleWarp.removeClass("active");
          $searchWarp.removeClass("search-warp-active");
          // $popMenuWarp.removeClass("popup-menu-warp-active");
        }
      });
      var $w = $(window);
      var $wWidth = $w.width();
      var mobile_resolution_size = "1200";
      var $dropToggle = $("[data-toggle='dropdown']");
      $dropToggle.on("click", function (event) {
        $(this).each(() => {
          if (
            $wWidth <= mobile_resolution_size &&
            $(this).attr("href") === "#"
          ) {
            event.preventDefault();
            event.stopPropagation();
            $(this).parent().siblings().removeClass("show");
            $(this).parent().toggleClass("show");
          } else if (
            $wWidth <= mobile_resolution_size &&
            !$(this).attr("href") !== "#"
          ) {
            event.preventDefault();
            event.stopPropagation();
            $(this).parent().siblings().removeClass("show");
            $(this).parent().toggleClass("show");
            $(this)
              .children("span")
              .on("click", () => {
                window.location.href = $(this).attr("href");
              });
          }
        });
      });
      $(window).scroll(function () {
        if ($(document).scrollTop() > 100) {
          $(".navbar-sticky").addClass("navbar-fixed");
        } else {
          $(".navbar-sticky").removeClass("navbar-fixed");
        }
      });
      $(".counting").counterUp({
        delay: 10,
        time: 1000,
      });
      // $('.mailchimp').ajaxChimp({
      //     url: "http://wplly.us5.list-manage.com/subscribe/post?u=91b69df995c1c90e1de2f6497&id=aa0f2ab5fa",
      //     callback: chimpCallback
      // });

      function chimpCallback(resp) {
        if (resp.result === "success") {
          $(".subscribe-alert")
            .html('<div class="alert alert-success">' + resp.msg + "</div>")
            .fadeIn(1000);
        } else if (resp.result === "error") {
          $(".subscribe-alert")
            .html('<div class="alert alert-danger">' + resp.msg + "</div>")
            .fadeIn(1000);
        }
      }
      $("#campaignmonitor").submit(function (e) {
        e.preventDefault();
        $.getJSON(
          this.action + "?callback=?",
          $(this).serialize(),
          function (data) {
            if (data.Status === 400) {
              alert("Error: " + data.Message);
            } else {
              alert("Success: " + data.Message);
            }
          }
        );
      });
      var $carouselDirection = $("html").attr("dir");
      var $carouselrtl = null;
      if ($carouselDirection == "rtl") {
        $carouselrtl = true;
      } else {
        $carouselrtl = false;
      }
      $(".carousel").each(function () {
        var $Carousel = $(this);
        $Carousel.owlCarousel({
          loop: $Carousel.data("loop"),
          autoplay: $Carousel.data("autoplay"),
          margin: $Carousel.data("space"),
          nav: $Carousel.data("nav"),
          dots: $Carousel.data("dots"),
          dotsSpeed: $Carousel.data("speed"),
          mouseDrag: $Carousel.data("drag"),
          touchDrag: $Carousel.data("drag"),
          pullDrag: $Carousel.data("drag"),
          rtl: $carouselrtl,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: $Carousel.data("slide-rs"),
            },
            1000: {
              items: $Carousel.data("slide"),
              center: $Carousel.data("center"),
            },
          },
        });
      });
      $(".slider-carousel").each(function () {
        var $Carousel = $(this);
        $Carousel.owlCarousel({
          loop: $Carousel.data("loop"),
          autoplay: $Carousel.data("autoplay"),
          margin: $Carousel.data("space"),
          nav: $Carousel.data("nav"),
          dots: $Carousel.data("dots"),
          center: $Carousel.data("center"),
          dotsSpeed: $Carousel.data("speed"),
          rtl: $carouselrtl,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: $Carousel.data("slide-rs"),
            },
            1000: {
              items: $Carousel.data("slide"),
            },
          },
          animateOut: "fadeOut",
        });
      });
      $(".testimonial-thumbs .testimonial-thumb").on("click", function () {
        $(this).siblings(".testimonial-thumb").removeClass("active");
        $(this).addClass("active");
        $(".testimonials-carousel").trigger("to.owl.carousel", [
          $(this).index(),
          300,
        ]);
      });
      $(".testimonials-carousel").on("changed.owl.carousel", function (event) {
        var items = event.item.count;
        var item = event.item.index;
        var owlDots = document.querySelectorAll(
          ".testimonial-thumbs .testimonial-thumb"
        );
        if (owlDots.length > 0) {
          owlDots[item].click();
        }
      });
      $(".process-content-carousel").on(
        "changed.owl.carousel",
        function (event) {
          var items = event.item.count;
          var item = event.item.index;
          $(".process-image-carousel").trigger("to.owl.carousel", [item, 800]);
        }
      );
      $(".entry-processes .images-holder .process-image-carousel").on(
        "changed.owl.carousel",
        function (event) {
          var items = event.item.count;
          var item = event.item.index;
          $(".entry-processes .entry-body .process-content-carousel").trigger(
            "to.owl.carousel",
            [item, 800]
          );
        }
      );
      var $imgPopup = $(".img-popup");
      $imgPopup.magnificPopup({
        type: "image",
      });
      $(".img-gallery-item").magnificPopup({
        type: "image",
        gallery: {
          enabled: true,
        },
      });
      $(".popup-video,.popup-gmaps").magnificPopup({
        disableOn: 700,
        mainClass: "mfp-fade",
        removalDelay: 0,
        preloader: false,
        fixedContentPos: false,
        type: "iframe",
        iframe: {
          markup:
            '<div class="mfp-iframe-scaler">' +
            '<div class="mfp-close"></div>' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            "</div>",
          patterns: {
            youtube: {
              index: "youtube.com/",
              id: "v=",
              src: "//www.youtube.com/embed/%id%?autoplay=1",
            },
          },
          srcAction: "iframe_src",
        },
      });
      var backTop = $("#back-to-top");
      if (backTop.length) {
        var scrollTrigger = 600,
          backToTop = function () {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > scrollTrigger) {
              backTop.addClass("show");
            } else {
              backTop.removeClass("show");
            }
          };
        backToTop();
        $(window).on("scroll", function () {
          backToTop();
        });
        backTop.on("click", function (e) {
          e.preventDefault();
          $("html,body").animate(
            {
              scrollTop: 0,
            },
            700
          );
        });
      }
      var $projectFilter = $(".projects-filter"),
        projectLength = $projectFilter.length,
        protfolioFinder = $projectFilter.find("a"),
        $projectAll = $("#projects-all");
      protfolioFinder.on("click", function (e) {
        e.preventDefault();
        $projectFilter.find("a.active-filter").removeClass("active-filter");
        $(this).addClass("active-filter");
      });
      if (projectLength > 0) {
        $projectAll.imagesLoaded().progress(function () {
          $projectAll.isotope({
            filter: "*",
            animationOptions: {
              duration: 750,
              itemSelector: ".project-item",
              easing: "linear",
              queue: false,
            },
          });
        });
      }
      protfolioFinder.on("click", function (e) {
        e.preventDefault();
        var $selector = $(this).attr("data-filter");
        $projectAll.imagesLoaded().progress(function () {
          $projectAll.isotope({
            filter: $selector,
            animationOptions: {
              duration: 750,
              itemSelector: ".project-item",
              easing: "linear",
              queue: false,
            },
          });
          return false;
        });
      });
      var aScroll = $('a[data-scroll="scrollTo"]');
      aScroll.on("click", function (event) {
        var target = $($(this).attr("href"));
        if (target.length) {
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000
          );
          if ($(this).hasClass("menu-item")) {
            $(this).parent().addClass("active");
            $(this).parent().siblings().removeClass("active");
          }
        }
      });
      $(".progressbar").each(function () {
        $(this).waypoint(
          function () {
            var progressBar = $(".progress-bar"),
              progressBarTitle = $(".progress-title .value");
            progressBar.each(function () {
              $(this).css("width", $(this).attr("aria-valuenow") + "%");
            });
            progressBarTitle.each(function () {
              $(this).css("opacity", 1);
            });
          },
          {
            triggerOnce: true,
            offset: "bottom-in-view",
          }
        );
      });
      var $sliderRange = $("#slider-range"),
        $sliderAmount = $("#amount");
      $sliderRange.slider({
        range: true,
        min: 0,
        max: 500,
        values: [50, 300],
        slide: function (event, ui) {
          $sliderAmount.val("$" + ui.values[0] + " - $" + ui.values[1]);
        },
      });
      $sliderAmount.val(
        "$" +
        $sliderRange.slider("values", 0) +
        " - $" +
        $sliderRange.slider("values", 1)
      );
      var contactForm = $(".contactForm"),
        contactResult = $(".contact-result");
      contactForm.validate({
        debug: false,
        submitHandler: function (contactForm) {
          $(contactResult, contactForm).html("Please Wait...");
          $.ajax({
            type: "POST",
            url: "assets/php/contact.php",
            data: $(contactForm).serialize(),
            timeout: 20000,
            success: function (msg) {
              $(contactResult, contactForm)
                .html(
                  '<div class="alert alert-success" role="alert"><strong>Thank you. We will contact you shortly.</strong></div>'
                )
                .delay(3000)
                .fadeOut(2000);
            },
            error: $(".thanks").show(),
          });
          return false;
        },
      });
      siteFooter();
      $(window).resize(function () {
        siteFooter();
      });

      function siteFooter() {
        var siteContent = $("#wrapperParallax");
        var siteFooter = $("#footerParallax");
        var siteFooterHeight = siteFooter.height();
        siteContent.css({
          "margin-bottom": siteFooterHeight,
        });
      }
      $("select").niceSelect();
      $(".collapse").on("shown.bs.collapse", function () {
        $(this).parent(".card").addClass("active-acc");
      });
      $(".collapse").on("hidden.bs.collapse", function () {
        $(this).parent(".card").removeClass("active-acc");
      });
      $("#loadMore").on("click", function (e) {
        e.preventDefault();
        $(".content.d-none").slice(0, 3).removeClass("d-none");
        if ($(".content.d-none").length == 0) {
          $("#loadMore").addClass("d-none");
        }
      });
      // var wow = new WOW({
      //     boxClass: 'wow',
      //     animateClass: 'animated',
      //     offset: 50,
      //     mobile: false,
      //     live: true
      // });
      // wow.init();
      var imagePointer = $(".img-hotspot .img-hotspot-pointer");
      var pointerInfo = $(".img-hotspot .img-hotspot-pointer .info");
      // imagePointer.each(function(index) {
      //     $(this).css('top', $(this).data('spot-y'));
      //     $(this).css('left', $(this).data('spot-x'));
      // });
      pointerInfo.each(function (index) {
        $(this).css("top", $(this).data("info-y"));
        $(this).css("left", $(this).data("info-x"));
      });
      $(".product-quantity span ").on("click", "a.plus, a.minus", function () {
        var qty = $(this).parents(".product-quantity").find(".pro-qunt");
        var val = parseFloat(qty.val());
        var max = parseFloat(qty.data("max"));
        var min = parseFloat(qty.data("min"));
        var step = parseFloat(qty.data("step"));
        if (isNaN(val)) {
          val = 0;
        }
        if ($(this).is(".plus")) {
          if (max && max <= val) {
            qty.val(max);
          } else {
            qty.val(val + step);
          }
        } else {
          if (min && min >= val) {
            qty.val(min);
          } else if (val > 1) {
            qty.val(val - step);
          }
        }
      });
    })(window.jQuery);
  },
  beforeUnmount() { },
};
</script>

<style scoped>
@import "../../../../assets/css/vendor.min.css";
@import "../../../../assets/css/style.css";
@import "../../../../assets/css/app.css";
</style>
